/*-------------------------
    Blog Articls
-------------------------*/
.blog-article-area {
    padding: 95px 0 94px 0;
    position: relative;
    z-index: 0;

    &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/shaped-8.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        content: '';
        z-index: -1;
    }
}

.single-blog-articles {
    .thumb {
        position: relative;
        z-index: 1;

        .time {
            position: absolute;
            left: 0;
            bottom: 0;
            color: rgba($white, .9);
            background-color: $base-color;
            padding: 5px 15px;
        }
    }

    .content {
        .post-meta {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-top: 17px;
            margin-bottom: 10px;

            li {
                display: inline-block;
                margin: 0 8px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    @include transition($transition);
                    color: $descr-color;

                    &:hover {
                        color: $base-color;
                    }
                }
            }
        }

        .title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            margin-bottom: 15px;

            a {
                color: $heading-color;
                @include transition($transition);

                &:hover {
                    color: $base-color;
                }
            }
        }

        p {
            font-size: 16px;
            line-height: 26px;
        }

        .readmore {
            i {
                font-size: 14px;
            }

            @include transition($transition);

            &:hover {
                color: $base-color;
            }
        }
    }
}

.submit-form {

    .required {
        display: none;
    }

}

#lcde-mobile-homepage-events {
    text-align: right;
}

@media only screen and (max-width: 692px) {
    #lcde-mobile-homepage-articles {
        margin: inherit;
        margin-bottom: 25px;
    }

    #lcde-mobile-homepage-events {
        margin-top: 25px;
        padding-right: initial;
    }

    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
    }

    .breadcrumb-actu {
        background-image: unset;
        background-size: unset;
        padding: unset;
        h1{
            color: unset!important;
        }
        .title{
            color: unset;
        }
        .page-list li {
            color: unset;
            a {
                color: unset;
            }
        }
        .page-list li:last-of-type {
            display: none;
        }
    }
}

@media only screen and (max-width: 991px) {
    .blog-page-content-area {

        padding-bottom: 0 !important;
    }

    #lcde-mobile-homepage-article-discover {
        margin-bottom: 40px !important;
    }
}
