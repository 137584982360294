/*-------------------------
    Error Page
-------------------------*/
.error-page-area{
    padding-top: 92px;
}
.error-content-area{
    text-align: center;
    .errro-text{
        font-size: 200px;
        line-height: 160px;
        font-weight: 700;
        color: $secondary-color;
        margin-bottom: 38px;
        display: block;
    }
    .title{
        font-weight: 24px;
        line-height: 34px;
        font-weight: 600;
    }
    .btn-wrapper{
        .boxed-btn {
            width: auto;
            border-radius: 3px;
        }
    }
    p{
        max-width: 500px;
        margin: 0 auto;
    }
    form{
        position: relative;
        z-index: 0;
        margin-top: 40px;
        .form-group{
            .form-control{
                width: 100%;
                height: 50px;
                padding-right: 70px;
                border: 1px solid #e2e2e2;
            }
        }
        .submit-btn{
            width: 60px;
            height: 50px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}