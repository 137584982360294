/*------------------------------
    Header Area
------------------------------*/

.header-area {
    position: relative;
    .header-carousel {
        overflow: hidden;
        .owl-item {
            &.active {
                .single-header-carousel-item {
                    .title {
                        -webkit-animation: 1s .9s flipInX both;
                        animation: 1s .9s flipInX both;
                    }
                    .subwrap {
                        -webkit-animation: 1s .9s flipInY both;
                        animation: 1s .9s flipInY both;
                    }
                    p {
                        -webkit-animation: 1s .9s fadeInLeft both;
                        animation: 1s .9s fadeInLeft both;
                    }
                    .btn-wrapper {
                        .boxed-btn {
                            -webkit-animation: 1s .9s rubberBand both;
                            animation: 1s .9s rubberBand both;
                        }
                    }
                }
            }
        }
    }
    .owl-nav {
        div {
            background-color: rgba(#000, .5);
            border-radius: 2px;
            height: 50px;
            left: 0;
            line-height: 50px;
            position: absolute;
            text-align: center;
            top: 50%;
            @include transform(translateY(-50%));
            @include transition($transition);
            width: 40px;
            &:hover {
                background-color: $base-color;
                color: $white;
            }
            &.owl-next {
                left: auto;
                right: 0;
            }
        }
    }
    .single-header-carousel-item {
        padding: 247px 0 260px;
        position: relative;
        text-align: center;
        z-index: 3;
        &.header-area-bg {
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &:before {
            background-color: rgba(#151b29, .65);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .subtitle {
            color: $white;
            font-family: $heading-font;
            font-size: 40px;
            font-weight: 400;
            line-height: 60px;
        }
        .title {
            color: $white;
            font-family: $heading-font;
            font-size: 50px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 40px;
        }
        p {
            color: rgba($white, .7);
            font-size: 16px;
            line-height: 26px;
            margin: 0 auto;
            margin-bottom: 17px;
            max-width: 550px;
        }
        .btn-wrapper {
            .boxed-btn {
                background-color: $base-color;
                margin-top: 20px;
                &:hover {
                    background-color: $yellow;
                }
            }
        }
    }
    .header-area-inner {
        z-index: 3;
        &.header-area-bg {
            background-position: top;
            background-size: cover;
        }
        &:before {
            background-image: url(../../img/bg/osons.jpg);
            background-position: center;
            background-size: cover;
            content: '';
            height: 90%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .subtitle {
            color: $white;
            font-family: $heading-font;
            font-size: 40px;
            font-weight: 400;
            line-height: 60px;
        }
        .title {
            color: $white;
            font-family: $heading-font;
            font-size: 50px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 27px;
        }
        p {
            color: rgba($white, .7);
            font-size: 16px;
            line-height: 26px;
            max-width: 550px;
        }
        .btn-wrapper {
            .boxed-btn {
                background-color: $base-color;
                margin-top: 20px;
                &:hover {
                    background-color: $yellow;
                }
            }
        }
    }
}

.typed-cursor {
    float: left;
    font-size: 49px;
    line-height: 49px;
}

.typed-cursor.typed-cursor--blink {
    color: $white;
}

.btn1 {
    @include animate(background-color);
    background-color: $color-saison;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    display: initial;
    font-size: 10px;
    font-weight: bold;
    margin: 5px auto 20px 0;
    padding: 5px 11px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background-color: $light-black;
        color: $white;

    }
}

.btn2 {
    @include animate(background-color);
    background-color: $light-black;
    border-radius: 4px;
    color: $white;
    display: initial;
    font-size: 10px;
    font-weight: bold;
    margin: 5px auto 20px 0;
    padding: 5px 11px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}
