/*------------------------------
    Blog Details
------------------------------*/

.blog-page-content-area{
    .single-blog-articles{
        margin-bottom: 30px;
    }
}

.blog-pagination{
    .pagination{
        display: block;
        .page-item{
            display: inline-block;
            &.active{
                .page-link{
                    background-color: $base-color;
                    color: $white;
                    border-color: $base-color;
                }
            }
            .page-link{
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-weight: 600;
                color: $heading-color;
                padding: 0;
                border-radius: 3px;
                @include transition($transition);
                &:hover{
                    background-color: $base-color;
                    color: $white;
                    border-color: $base-color;
                }
            }
        }
    }
}

.single-blog-classic-post{
    &.format-gallery{
        .marafi-post-gallery{
            .owl-nav div{
                position: absolute;
                left: 20px;
                top: 50%;
                font-size: 40px;
                color: rgba($base-color,.6);
                @include transition($transition);
                @include transform(translateY(-50%));
                &:hover{
                    color: $base-color;
                }
                &.owl-next{
                    left: auto;
                    right: 20px;
                }
            }
        }
    }
    &.format-link{
        .entry-content{
            position: relative;
            z-index: 0;
            overflow: hidden;
            background-color: $secondary-color;;
            &:after{
                position: absolute;
                left: 30px;
                top: 40px;
                @include fontawesomeIcon("\f0c1");
                font-size: 60px;
                line-height: 60px;
                // color: rgba($white,.4);
                color: $base-color;
                z-index: -1;
            }
            .link{
                margin-left: 90px;
                font-size: 20px;
                font-weight: 400;
                line-height: 35px;
                font-style: italic;
                color: rgba($white,.6);
            }
        }
    }
    &.format-quote{
        .entry-content{
            position: relative;
            z-index: 0;
            overflow: hidden;
            background-color: $secondary-color;;
            &:after{
                position: absolute;
                left: 30px;
                top: 40px;
                @include fontawesomeIcon("\f10d");
                font-size: 60px;
                line-height: 60px;
                // color: rgba($white,.4);
                color: $base-color;
                z-index: -1;
            }
            .quote{
                margin-left: 90px;
                font-size: 20px;
                font-weight: 400;
                line-height: 35px;
                font-style: italic;
                color: rgba($white,.6);
            }
        }
    }
    .entry-content{
        background-color: #F4F4F4;
        padding: 32px 30px 32px 30px;
        margin-bottom: 30px;
        .title{
            font-size: 30px;
            line-height: 40px;
            a{
                color: $heading-color;
                @include transition($transition);
                &:hover{
                    color: $base-color;
                }
            }
        }
        .post-meta{
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 22px;
            li{
                margin: 0 10px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.cats{
                    a{
                        margin: 0 5px;
                        position: relative;
                        z-index: 0;
                        &:after{
                            position: absolute;
                            right: -5px;
                            top: 0;
                            content: ',';
                        }
                        &:last-child{
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
                display: inline-block;
                a{
                    color: $descr-color;
                    @include transition($transition);
                    &:hover{
                        color: $base-color;
                    }
                }
            }
        }
        .readmore{
            color: $descr-color;
            @include transition($transition);
            i{
                visibility: hidden;
                opacity: 0;
                @include transform(translateX(-20px));
                @include transition($transition);
            }
            &:hover{
                color: $base-color;
                i{
                    visibility: visible;
                    opacity: 1;
                    @include transform(translateX(-2px));
                }
            }
            i{
                font-size: 14px;
            }
        }
    }
    &:last-child{
        margin-bottom: 0;
    }
}