/*-------------------------
    Blog Details Page
-------------------------*/
.blog-details-page-content-area{
    padding: 50px 0 30px 0;
    .entry-footer{
        border: 1px solid rgba(#000,.1);
        padding: 15px 20px 17px 20px;
        .left{
            display: inline-block;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                li{
                    display: inline-block;
                    margin: 0 5px;
                    &.title{
                        font-weight: 600;
                        color: $heading-color;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        color: $descr-color;
                        margin: 0 7px;
                        position: relative;
                        z-index: 0;
                        &:after{
                            position: absolute;
                            right: -6px;
                            top: 0;
                            content: ',';
                        }
                        &:first-child{
                            margin-left: 0;
                        }
                        &:last-child{
                            margin-right: 0;
                            &:after{
                                display: none;
                            }
                        }
                        @include transition($transition);
                        &:hover{
                            color: $base-color;
                        }
                    }
                }
            }
        }
        .right{
            display: inline-block;
            float: right;
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                li{
                    display: inline-block;
                    margin: 0 5px;
                    &.title{
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 600;
                        color: $heading-color;
                    }   
                    a{
                        color: $descr-color;
                        @include transition($transition);
                        &:hover{
                            color: $base-color;
                        }
                    }
                }
            }
        }
    }
    .single-post-details-item{
        .thumb{
            margin-bottom: 30px;
        }
        .entry-content{
            .title{
                font-size: 30px;
                line-height: 40px;
                font-weight:600;
                margin-bottom: 15px;
            }
            .gal-img{
                margin:10px 0 15px 0;
            }
            .post-meta{
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                li{
                    margin: 0 8px;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    display: inline-block;
                    a{
                        @include transition($transition);
                        color: $descr-color;
                        &:hover{
                            color: $base-color;
                        }
                    }
                }
            }
            
            blockquote{
                background-color: #f4f4f4;
                border-left: 5px solid $base-color;
                padding: 22px 30px 24px 30px;
                font-size: 18px;
                font-style: italic;
                line-height: 28px;
                margin: 25px 0;
                cite{
                    display: block;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 26px;
                    margin-top: 20px;
                }
            }
        }
        .thumb{}
    }
    .comment-area{
        padding-top: 32px;
        .comment-title{
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
            margin-bottom: 25px;
        }
        .comment-list{
            margin: 0;
            padding: 0;
            list-style: none;
            list-style-type: none;
            li{
                margin-bottom: 8px;
                list-style: none;
                &:last-child{
                    margin-bottom: 0;
                }
                ol{
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    list-style-type: none;
                    li{
                        margin-left: 50px;
                        list-style-type: none;
                        list-style: none;
                    }
                }
                .single-comment-wrap{
                    display: flex;
                    align-self: flex-start;
                    .thumb{
                        img{
                            margin-bottom: 10px;
                        }
                        .date,.time{
                            display: block;
                            font-size: 14px;
                            line-height: 20px;
                        }
                        margin-right: 20px;
                    }
                    .content{
                        flex: 1;
                        position: relative;
                        .title{
                            font-size: 18px;
                            line-height: 28px;
                            font-weight: 600;
                            font-family: $body-font;
                        }
                        p{
                            font-size: 16px;
                            line-height: 26px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        .reply{
                            position: absolute;
                            top: 0;
                            right: 0;
                            color: $descr-color;
                            @include transition($transition);
                            &:hover{
                                color: $base-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .comment-form-wrap{
        margin-top: 0px;
        .title{
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .comment-form{
            .form-group{
                &.textarea{
                    .form-control{
                        min-height: 160px;
                        resize: none;
                    }
                }
                .form-control{
                    height: 50px;
                    line-height: 50px;
                }
            }
            .submit-btn{
                height: 50px;
                width: 180px;
                border-radius: 5px;
                font-weight: 600;
            }
        }
    }
}