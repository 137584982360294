/*====================
** Normalize
====================*/

html {
    font-family: $body-font;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
}
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */ }
body {
    font-family: $body-font;
    font-size: $base-font-size;
    margin: 0;
    color: $descr-color;
    overflow-x: hidden;
}

// Typography
h1 {
    font-size: 46px;
    line-height: 1.0833333333333333;
    color: #fff !important;
}

h2 {
    font-size: $font-size-h2;
    line-height: 1.4444444444444444;
}

h3 {
    font-size: $font-size-h3;
    color: #ed1c24 !important;
    line-height: 1.0833333333333333;
}

h4 {
    font-size: $font-size-h4;
    line-height: 1.2380952380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-family: $heading-font;
}

p {
    font-size: $base-font-size;
    color: $descr-color;
    line-height: 1.625;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin-bottom: 0;
}

#p-date{
    color: rgba(63, 63, 63, 0.8);
    font-size: 80%;
}

.p-subtitle{
    color: black;
}

.p-chapo{
}

a {
    color: inherit;
    text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a i {
    padding: 0 2px;
}

img {
    max-width: 100%;
}
ol {
    counter-reset: counter;
    padding-left: 0; }
    ol li {
      list-style: none;
      margin-bottom: 1rem; }
      ol li:before {
        counter-increment: counter;
        content: counter(counter);
        font-weight: 500;
        margin-right: 10px; }
/*input and button type focus outline disable*/

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #ddd;
}

/**
 * 5.0 - Alignments
 */

.alignleft {
    float: left;
}

.alignright {
    float: right;
}

.aligncenter {
    clear: both;
    display: block;
    margin: 0 auto 1.75em;
}

.alignfull {
    margin: 1.5em 0;
    max-width: 100%;
}
.alignwide {
    max-width: 1100px;
}
wp-block-video video {
    max-width: 636px;
}

.wp-block-image img {
    display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
    width: 100%
}

.wp-block-image.alignfull img {
    width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
    margin: 1.5em auto;
}

.wp-block-cover-text p {
    padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
    padding: 0 14px;
}

.wp-block-table {
    display: block;
    overflow-x: auto;
}

.wp-block-table table {
    border-collapse: collapse;
    width: 100%
}

.wp-block-table td, .wp-block-table th {
    padding: .5em;
}


.wp-block-embed.type-video > .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wp-block-quote.is-large {
    margin: 0 auto 16px;
}

.wp-block-pullquote>p:first-child {
    margin-top: 0;
}

.wp-block-separator {
    margin: 3em auto;
    padding: 0;
}

@media screen and (min-width: 768px) {
    .wp-block-cover-text p {
      padding: 1.5em 0;
    }
  
    .entry-content > * {
      padding-left: 0px;
      padding-right: 0px;
    }
  
}
  /*--------------------------------------------------------------
# Block Color Palette Colors
--------------------------------------------------------------*/
.has-strong-blue-color {
    color: #0073aa;
}
  
.has-strong-blue-background-color {
    background-color: #0073aa;
}

.has-lighter-blue-color {
    color: #229fd8;
}

.has-lighter-blue-background-color {
    background-color: #229fd8;
}

.has-very-light-gray-color {
    color: #eee;
}

.has-very-light-gray-background-color {
    background-color: #eee;
}

.has-very-dark-gray-color {
    color: #444;
}

.has-very-dark-gray-background-color {
    background-color: #444;
}

typed{
    color: #FFCC00;
}