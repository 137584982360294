/*-----------------------
    Faq Page
-----------------------*/

.faq-page-content-area{

}

.accordion-content-area{
    .card{
        border: none;
        &:last-child{
            .card-header{
                a{
                    margin-bottom: 0;
                }
            }
            .card-body{
                margin-bottom: 0;
            }
        }
        .card-header{
            padding: 0;
            border: none;
            background-color: transparent;
            a{
                background-color: #f4f4f4;
                display: block;
                padding: 10px 30px;
                font-size: 16px;
                line-height: 28px;
                font-weight: 600;
                position: relative;
                z-index: 0;
                margin-bottom: 20px;
                cursor: pointer;
                &:after{
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    @include fontawesomeIcon("\f067");
                    font-size: 14px;
                }
                &[aria-expanded="true"]{
                    margin-bottom: 0;
                    &:after{
                        content: "\f068";
                    }
                }
            }
        }
        .card-body{
            font-size: 16px;
            line-height: 26px;
            padding: 10px 30px 30px 30px;
            background-color: #f4f4f4;
            margin-bottom: 20px;
        }
    }
}