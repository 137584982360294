/*-----------------------------
    Team Member area
-----------------------------*/
.team-member-area {
    padding: 95px 0 100px 0;

    &.team-member-bg {
        background-image: url(../../img/bg/team-memeber-bg.jpg);
        background-position: center;
        background-size: cover;
        margin-top: 50px;
    }
}

.single-team-member {
    &:hover {
        .thumb {
            .hover {
                .hover-inner {
                    background-color: $base-color;

                    .title {
                        color: $white;
                    }

                    .subtitle {
                        color: rgba($white, .75);
                    }
                }
            }
        }
    }

    .thumb {
        position: relative;
        z-index: 0;

        .hover {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;

            .hover-inner {
                text-align: center;
                background-color: rgba($white, .75);
                @include transition($transition);

                .title {
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    padding-top: 5px;
                    @include transition($transition);
                }

                .subtitle {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    display: block;
                    @include transition($transition);
                }

                .social-icon {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    background-color: rgba($white, .85);
                    padding: 10px 0 8px 0;

                    li {
                        display: inline-block;
                        margin: 0 5px;

                        a {
                            color: $descr-color;

                            &:hover {
                                opacity: .8;
                            }

                            &.facebook {
                                color: $facebook;
                            }

                            &.instagram {
                                color: $instagram;
                            }

                            &.google-plus {
                                color: $google-plus;
                            }

                            &.linkedin {
                                color: $linkedin;
                            }
                        }
                    }
                }
            }
        }
    }

}

.submit-btn-1 {
    width: 250px;
    height: auto;
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: $white;
    background-color: $base-color;
    border: none;
    @include transition($transition);
    cursor: pointer;

    &:hover {
        background-color: #ffcc00;
    }

    &.btn-rounded {
        border-radius: 30px;
    }

    &.btn-center {
        display: block;
        margin: 25px auto 0;
    }

    &:focus {
        outline: none;
    }
}

.sf-collection{

    label{
        display: none;
    }
}

#reset_password_save{
    height: auto;
    margin-top: 30px;
}

.required{
    margin: 5px;
}

.form-inline {
    input {
        max-width: 200px;
    }
    button {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 992px) {

    .col {
        margin-bottom: 40px;
    }

}

@media only screen and (max-width: 992px) {

    .widget:last-child {
        margin-bottom: 15px;
    }

}
