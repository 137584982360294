/*-----------------------------
    Portfolio Details Page
-----------------------------*/
.portfolio-details-area{
    
}
.portfolio-content-area{
    .thumb{}
    .entry-content{
        .img-thumg{
            margin: 10px 0 25px 0;
        }
        margin-top: 25px;
        .title{
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
        }
        .post-meta{
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 20px;
            li{
                display: inline-block;
                margin: 0 10px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                a{
                    color: $descr-color;
                    @include transition($transition);
                    &:hover{
                        color: $base-color;
                    }
                }
            }
        }
        p{
            font-size: 16px;
            line-height: 26px;
            color: $descr-color;
        }
    }       
}

.portfolio-widget-area{
    background-color: #f4f4f4;
}
.widget_porject_details{
    .project-list{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            display: block;
            text-align: right;
            strong{
                color: $heading-color;
                float: left;
                font-weight: 600;
            }
            a{
                color: $descr-color;
                @include transition($transition);
                margin: 0 5px;
                &:hover{
                    color: $base-color;
                }
            }
        }
    }
    .share{
        margin: 0;
        padding: 0;
        list-style: none;
        margin-top: 18px;
        li{
            margin: 0 10px;
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }
            &.title{
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                color: $heading-color;
                font-weight: 600;
            }
            display: inline-block;
            a{

            }
        }
    }
}

