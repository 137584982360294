/*------------------------
    Portfolio Page
------------------------*/
.portfolio-area {
    padding: 0 0 85px 0;

    h4 {
        margin: 0 0 15px 15px;
    }
}

.portfolio-nav-area {
    text-align: center;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 28px;

        li {
            display: inline-block;
            margin: 0 10px;
            position: relative;
            font-weight: 600;
            cursor: pointer;
            @include transition($transition);

            &.active, &:hover {
                color: $base-color;
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                right: -15px;
                top: 50%;
                width: 5px;
                height: 2px;
                background-color: $descr-color;
                content: '';
            }
        }
    }
}

.single-masorny-item {
    position: relative;
    z-index: 0;
    margin-bottom: 30px;

    &:hover {
        .hover {
            visibility: visible;
            opacity: 1;
        }
    }

    .thumb {
        img {
            width: 100%;
        }
    }

    .hover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .4);
        content: '';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        @include transition($transition);

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    background-color: $white;
                    text-align: center;
                    @include transition($transition);
                    color: $base-color;

                    &:hover {
                        background-color: $base-color;
                        color: $white;
                    }
                }
            }
        }
    }
}