/*---------------------------
    What We Do Area
---------------------------*/
.what-we-do-area {
    position: relative;
    z-index: 0;
    margin-bottom: 60px;

    .shped-1 {
        position: absolute;
        right: 30px;
        top: 10%;
        animation: updown 5s linear 0s infinite reverse;
    }

    .shped-2 {
        position: absolute;
        right: 50px;
        top: 60%;
        animation: leftright2 5s linear 0s infinite reverse;
    }

    .shped-3 {
        position: absolute;
        right: 80px;
        top: 40%;
        animation: leftright 5s linear 0s infinite reverse;
    }

    .shped-4 {
        position: absolute;
        left: 90px;
        top: 30%;
    }

    .col-sm-5 {
        padding-left: 0;
        padding-right: 0;
    }

    .boxed-btn {
        margin-top: 10px;
    }

    .left-content {
    }

    .right-content {
        .subtitle {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            color: $base-color;
        }

        .title {
            font-size: 36px;
            line-height: 46px;
            font-weight: 600;
            color: $heading-color;
            margin-bottom: 25px;
        }

        .what-we-do-list {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-top: 50px;
        }
    }
}

.single-what-we-do {
    display: flex;
    align-self: flex-start;
    border-bottom: 1px solid #dedede;
    padding-bottom: 20px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;

        .content {
            p {
                margin-bottom: 0;
            }
        }
    }

    .icon {
        font-size: 50px;
        line-height: 50px;
        color: $base-color;
    }

    .content {
        flex: 1;
        margin-left: 30px;

        .title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
}

@keyframes leftright {
    0% {
        @include transform(translateX(-30px));
    }
    50% {
        @include transform(translateX(0px));
    }
    100% {
        @include transform(translateX(-30px));
    }
}

@keyframes leftright2 {
    0% {
        @include transform(translateY(-30px) translateX(-10px));
    }
    50% {
        @include transform(translateY(0px) translateX(-0px));
    }
    100% {
        @include transform(translateY(-30px) translateX(-10px));
    }
}

@media only screen and (max-width: 767px) {
    .blog-page-content-area {
        padding-bottom: 0 !important;
    }

    .price-plan-area {
        padding-bottom: 10px !important;
    }

}

@media only screen and (max-width: 991px) {
    .shped-4 {
        display: none;
    }

    .breadcrumb-event {
        padding: 40px;
    }

    h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
    }
}