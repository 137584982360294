/*------------------------------
    Navbar Area
------------------------------*/
.dropdown:hover > .dropdown-menu {
    display: block;
    color: $violet;
}

.navbar-area .navbar-collapse {
    width: 130%
}

.navbar-area {
    &.absolute {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        background-color: transparent;;
        border-bottom: 1px solid rgba($white, .2);
    }

    @include transition(.5s ease-in);
    background-color: $white;
    padding: 0;

    .navbar-brand {
        padding-top: 0;
    }

    &.white {
        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: rgba($base-color, .8);
                    }
                }
            }
        }

        .nav-right-content {
            ul {
                li {
                    color: rgba($white, .8);
                }
            }
        }

        &.nav-fixed {
            background-color: $white;
        }
    }

    &.nav-fixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9999;
        @include transition(.5s ease-in);
        border-bottom: 1px solid rgba(#000, .1);

        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    &:hover {
                        .dropdown-menu {
                            @include transition($transition);
                            top: 100%;
                        }
                    }
                }
            }
        }
    }

    .navbar-collapse {
        justify-content: flex-end;
        @include transition(.5s ease-in);

        .navbar-nav {
            .nav-item {
                display: inline-block;
                font-size: 12px;
                line-height: 42px;
                font-weight: 400;
                padding: 15px 5px;
                position: relative;

                &.mega-menu {
                    position: static;

                    &.show {
                        .mega-menu-wrapper {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &:hover {
                        .mega-menu-wrapper {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .mega-menu-wrapper {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        width: 100%;
                        z-index: 2;
                        visibility: hidden;
                        opacity: 0;
                        @include transition($transition);

                        .mega-menu-container {
                            background-color: #fff;
                            padding: 28px 30px 23px 30px;

                            .mega-menu-columns {
                                .title {
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #242424;
                                    margin-bottom: 17px;
                                    font-family: $body-font;
                                }

                                .menga-menu-page-links {
                                    li {
                                        line-height: 30px;

                                        a {
                                            color: $base-color;
                                            @include transition($transition);

                                            &:hover {
                                                color: $base-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    padding-left: 0;
                }

                &:hover {
                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        margin: 0;
                        top: 80px;
                        @include transition(.5s ease-in);

                        .dropdown-item {
                            font-size: 16px;
                            font-weight: 400;
                            color: $base-color;
                            line-height: 40px;
                            @include transition($transition);

                            &:last-child {
                                border-bottom: none;
                            }

                            &:hover {
                                background-color: $base-color;
                                color: $white;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    @include transition($transition);
                    top: 85px;

                    &.show {
                        padding: 0;
                        border-radius: 0;

                        .dropdown-item {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 40px;
                            color: #242424;
                            @include transition($transition);

                            &:last-child {
                                border-bottom: none;
                            }

                            &:hover {
                                background-color: $base-color;
                                color: $white;
                            }
                        }
                    }
                }

                &:hover {
                    .nav-link {
                        color: $blue;
                    }
                }

                &.active {
                    .nav-link {
                        color: $base-color;
                    }
                }

                &:last-child {
                    padding-right: 0;
                }

                .nav-link {
                    color: #242424;
                    @include transition($transition);
                    font-weight: 400;

                    &.pl-0 {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .nav-right-content {
        ul {
            padding: 0;
            list-style: none;
            margin: 0 0 0 15px;

            li {
                display: inline-block;
                margin: 0 15px;

                &:last-child {
                    margin-right: 0;
                }

                &.search {
                    cursor: pointer;
                    @include transition($transition);

                    &:hover {
                        color: $base-color;
                    }
                }

                &.nav-btn {
                    .btn-boxed {
                        font-weight: 600;
                        border-radius: 5px;
                        background-color: $base-color;

                        &:hover {
                            background-color: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}

#first-menu {
    color: $base-color!important;
    cursor: pointer;
    margin: auto;

    &:hover {
        color: $blue;
        transition: all 0.3s ease-in;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
        transition-delay: 0s;
    }
}

@media only screen and (max-width: 991px) {
    #first-menu {
        display: contents;
    }
    .navbar-area .navbar-collapse .navbar-nav .nav-item .dropdown-menu {
        overflow: visible !important;
        transition: .3s ease-in;
    }

}
