/*----------------------------
    Price Plan area
-----------------------------*/
.price-plan-area{
    position: relative;
    z-index: 0;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/shaped-5.png);
        background-position: right top;
        background-repeat: no-repeat;
        content: '';
        z-index: -1;
    }
    .shape-1{
        position: absolute;
        right: 30px;
        top: 10%;
        animation: leftright 5s linear 0s infinite reverse;
    }
    .shape-2{
        position: absolute;
        right: 80px;
        top: 15%;
        animation: rotate360 2s linear 0s infinite reverse;
    }
}
.price-plan-tab-nav{
    margin-bottom: 30px;
    .nav-tabs{
        display: block;
        border: none;
        text-align: center;
        .nav-item{
            display: inline-block;
            .nav-link{
                border: 1px solid #dedede;
                width: 150px;
                height: 45px;
                padding: 0;
                line-height: 45px;
                border-radius: 0px;
                @include transition($transition);
                &.active,&:hover{
                    background-color: $base-color;
                    color: $white;
                    border-color: $base-color;
                }
            }
        }
    }
}
.single-price-plan{
    border: 1px solid #c8c8c8;
    text-align: center;
    @include transition($transition);
    &:hover{
        .price-header{
            .price-wrap{
                .sign,.price{
                    color: $base-color;
                }
            }
        }
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2); 
        border-color: transparent;
    }
    .price-header{
        padding: 23px 0 0 0;
        .name{
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
            color: $base-color;
            margin-bottom: 20px;
        }
        .price-wrap{
            border-top: 1px solid #dedede;
            padding: 10px 80px 13px 80px;
            position: relative;
            z-index: 0;
            &:after{
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 63%;
                height: 3px;
                background-color: #dedede;
                content: '';
                @include transform(translateX(-50%));
            }
            .price-inner{
                position: relative;
                z-index: 0;
                &:after{
                    position: absolute;
                    left: -55px;
                    top: -11px;
                    height: 96px;
                    width: 1px;
                    background-color: #dedede;
                    content: '';
                    @include transform(skew(30deg));
                }
                &:before{
                    position: absolute;
                    right: -55px;
                    top: -11px;
                    height: 96px;
                    width: 1px;
                    background-color: #dedede;
                    content: '';
                    @include transform(skew(-30deg));
                }
            }
            
            display: inline-block;
            .sign{
                font-size: 24px;
                line-height: 34px;
                position: relative;
                top: -10px;
                @include transition($transition);
            }
            .price{
                font-size: 40px;
                line-height: 50px;
                font-weight: 600;
                color: #121a2f;
                @include transition($transition);
            }
            .month{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #b2b2b2;
                text-transform: uppercase;
            }
        }
    }
    .price-body{
        padding: 27px 0 28px 0;
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                display: block;
                margin: 15px 0;
                color: $heading-color;
                i{
                    color: $base-color;
                }
            }
        }
    }
    .price-footer{
        padding-bottom: 30px;
        .boxed-btn{
            width: 150px;
            height: 45px;
            line-height: 45px;
        }
    }
}
@keyframes rotate360{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}