/*-----------------------
    About Area
-----------------------*/
.about-us-area{
    position: relative;
    z-index: 0;
    padding: 0 0 20px;
    .about-right-circle{
        position: absolute;
        left: -20%;
        top: 50%;
        @include transform(translateY(-50%));
        img{
            animation: updown 8s linear 0s infinite ;
        }
    }
    &:after{
        position: absolute;
        right: 0;
        top: 50%;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/dotted-bg.png);
        background-position: right;
        content: '';
        background-repeat: no-repeat;
        @include transform(translateY(-50%));
        z-index: -1;
    }
    .left-content-area{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        .subtitle{
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            color: $base-color;
        }
        .title{
            font-size: 36px;
            line-height: 46px;
            font-weight: 600;
            color: $heading-color;
            margin-bottom: 25px;
        }
        p{
            font-size: 16px;
            line-height: 26px;
        }
        .boxed-btn{
            font-weight: 400;
        }
    }
    .right-content-area{
      
    }
}




@-webkit-keyframes updown {
    0% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); }
    10% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    20% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    30% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    40% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    50% {
      -ms-transform: translateY(50px);
      /* IE 9 */
      -webkit-transform: translateY(50px);
      /* Chrome, Safari, Opera */
      transform: translateY(50px); }
    60% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    70% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    80% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    90% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    100% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); } }
  
  @-moz-keyframes updown {
    0% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); }
    10% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    20% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    30% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    40% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    50% {
      -ms-transform: translateY(50px);
      /* IE 9 */
      -webkit-transform: translateY(50px);
      /* Chrome, Safari, Opera */
      transform: translateY(50px); }
    60% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    70% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    80% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    90% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    100% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); } }
  
  @-o-keyframes updown {
    0% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); }
    10% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    20% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    30% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    40% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    50% {
      -ms-transform: translateY(50px);
      /* IE 9 */
      -webkit-transform: translateY(50px);
      /* Chrome, Safari, Opera */
      transform: translateY(50px); }
    60% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    70% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    80% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    90% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    100% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); } }
  
  @keyframes updown {
    0% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); }
    10% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    20% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    30% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    40% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    50% {
      -ms-transform: translateY(50px);
      /* IE 9 */
      -webkit-transform: translateY(50px);
      /* Chrome, Safari, Opera */
      transform: translateY(50px); }
    60% {
      -ms-transform: translateY(40px);
      /* IE 9 */
      -webkit-transform: translateY(40px);
      /* Chrome, Safari, Opera */
      transform: translateY(40px); }
    70% {
      -ms-transform: translateY(30px);
      /* IE 9 */
      -webkit-transform: translateY(30px);
      /* Chrome, Safari, Opera */
      transform: translateY(30px); }
    80% {
      -ms-transform: translateY(20px);
      /* IE 9 */
      -webkit-transform: translateY(20px);
      /* Chrome, Safari, Opera */
      transform: translateY(20px); }
    90% {
      -ms-transform: translateY(10px);
      /* IE 9 */
      -webkit-transform: translateY(10px);
      /* Chrome, Safari, Opera */
      transform: translateY(10px); }
    100% {
      -ms-transform: translateY(0px);
      /* IE 9 */
      -webkit-transform: translateY(0px);
      /* Chrome, Safari, Opera */
      transform: translateY(0px); } }

  .single-history-item {
    margin-top: 20px;
  }