
//== Template Main Color
$base-color: #ed1c24;
$secondary-color: #121a2f ;//#03040d;
$bg-color:#f7f7f7;
$white: #fff;
$sbg-1:#f7f7f7 ;
$sbg-2:#19206e;
//== heading color
$heading-color: #242424;
$light-black: #2d2d2d;
$descr-color: #8c8c8c;
$violet: #939;
$yellow: #fc0;
$blue: #39c;
$red: #f00;
$green: #9c6;
$color-saison: #ef7874;

//==gradient color

$danger: #dc3545;

//== social media colors

$facebook: #3b5999;
$twitter: #55acee;
$dribbble: #ea4c89;
$behance: #131418;
$google-plus: #dd4b39;
$twitter: #00acee;
$linkedin: #0077b5;
$instagram: #e4405f;


/*-----------------
    @Typography
-----------------*/

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;
//== font sizes
$base-font-size: 16px !default;
$font-size-h1: 60px !default;
$font-size-h2: 52px !default;
$font-size-h3: 26px !default;
$font-size-h4: 22px !default;
//** Unit-less `line-height` for use in components like buttons.
$line-height: 1.6 !default; // 24/15
// border-radius
$border-radius-5: 5px !default;
$border-radius-25: 25px !default;
$border-radius-3: 3px !default;
$border-radius-circle: 50% !default;
//  transition
$transition: all .3s ease-in;
$transition-long: all .5s ease-in;


.btn-primary {
    color: #fff;
    background-color: $base-color;
    border-color: $base-color;
}
.btn-primary:hover {
    color: $secondary-color;
    background-color: $yellow;
    border-color: $yellow;
}
