/*----------------------------
    Footer Area
-----------------------------*/
.footer-area {

    h2, h3, h4, p, div, span {
        color: $white;
    }
}

.form-section {
    position: relative;
    padding: 95px 0 95px 350px;
    background: url(../../img/vrac-logo/Logo-main-filete-blanc-rvb-2.png) no-repeat left, $violet;
    background-size: 400px;
    border-left: 1px solid #222222;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.sec-title-two {
    position: relative;
    margin-bottom: 22px;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.form-section .form-column .inner-column {
    position: relative;
    padding-right: 100px;
}

.default-form .form-group {
    position: relative;
    margin-bottom: 18px;
    padding: 10px 10px 10px 0;
}

.default-form .form-group input[type="text"], .default-form .form-group input[type="password"], .default-form .form-group input[type="tel"], .default-form .form-group input[type="email"], .default-form .form-group select {
    position: relative;
    display: block;
    width: 100%;
    line-height: 18px;
    padding: 10px 0px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    color: #242424;
    background-position-x: 0%;
    background-position-y: 0%;
    background: rgba(0, 0, 0, 0) none repeat scroll;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-bottom: 1px solid #242424;
    transition: all 300ms ease;
}

.form-section .inner-column {
    position: relative;
    padding-left: 80px;
    margin-top: 30px;
}

.col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9 {
    float: left;
}

.form-section .info-column {
    position: relative;
}

/*
 inner-column
*/

.form-section .info-column .inner-column {
    position: relative;
    padding-left: 80px;
    margin-top: 30px;
    border-left: 1px solid #222222;
}

.list-style-one {
    position: relative;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-style-one li {
    position: relative;
    padding-left: 62px;
    margin-bottom: 25px;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    font-family: 'Open Sans', sans-serif;
}

.copyright-area {
    background-color: $white;
    border-top: 1px solid rgba($white, .2);
    text-align: right;
    color: rgba($white, .6);
    padding: 15px 5px;

    a {
        color: $base-color;
    }
}

.team-area {
    .thumb {
        position: relative;
        z-index: 0;

        .hover {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 80%;

            .hover-inner {
                text-align: center;
                @include transition($transition);

                .title {
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    padding-top: 5px;
                    @include transition($transition);
                }

                .subtitle {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    display: block;
                    @include transition($transition);
                }

                .social-icon {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    padding: 10px 0 8px 0;

                    li {
                        display: inline-block;
                        margin: 0 5px;

                        a {
                            color: $white;

                            &:hover {
                                opacity: .8;
                            }

                            &.facebook {
                                color: $facebook;
                            }

                            &.instagram {
                                color: $instagram;
                            }

                            &.twitter {
                                color: $twitter;
                            }

                            &.linkedin {
                                color: $linkedin;
                            }
                        }
                    }
                }
            }
        }
    }

}

#copyright-lcde a {
    float: left;
}

@media only screen and (max-width: 1137px) {

    .form-section {
        padding: 0;
    }
}

@media only screen and (max-width: 991px) {

    #lcde-contact {
        width: -moz-min-content;
        width: min-content;
    }
    .form-section {
        padding: inherit;
        background-position: center;
        background-size: contain;
    }
}

@media only screen and (max-width: 767px) {
    .copyright-area {
        margin-top: 0 !important;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .copyright-area {
        margin-top: 0 !important;
    }
}
