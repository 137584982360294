/*----------------------------
    Testimonial Area
----------------------------*/
.testimonial-area{
    padding: 95px 0 100px 0;
    .section-title{
        margin-bottom: 45px;
    }
}
.single-testimonial-item{
    border: 1px solid #e2e2e2;
    text-align: center;
    position: relative;
    z-index: 0;
    margin-top: 40px;
    padding: 65px 20px 25px 20px;
    .thumb{
        position: absolute;
        left: 50%;
        top: -40px;
        @include transform(translateX(-50%));
        img{
            width: 80px;
            height: 80px;
            margin: 0 auto;
            border-radius: 50%;
        }
    }
    .content{
        .title{
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            margin-bottom: 0;
        }
        .subtitle{
            font-size: 14px;
            line-height: 26px;
        }
        p{
            font-size: 16px;
            line-height: 26px;
            color: $descr-color;
        }
    }
}