/*-----------------------
    Support Bar
-----------------------*/
.support-area{
    background-color: #f7f7fb;
    padding: 15px 0;
    .icon-list{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            display: inline-block;
            margin: 0 13px;
            position: relative;
            font-size: 14px;
            &:after{
                position: absolute;
                right: -15px;
                top: 5px;
                height: 16px;
                width: 1px;
                background-color: rgba($descr-color,.6);
                content:'';
            }
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
                &:after{
                    display: none;
                }
            }
            color: $descr-color;
            a{
                color: $descr-color;
                font-size: 14px;
                @include transition($transition);
                &:hover{
                    color: $base-color;
                }
            }
        }
    }
    .left-content{
        display: inline-block;
    }
    .right-content{
        display: inline-block;
        float: right;
    }
}