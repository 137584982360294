/*---------------------------
** Global style
---------------------------*/

.remove-col-padding {
    padding: 0;
}

.navbar-toggler {
    border-color: rgba(0, 0, 0, 0.10);
}

.yellow-bg {
    background-color: $yellow;
}

.blue-bg {
    background-color: $blue;
}

.black-bg {
    background-color: $violet;
}

.navbar.navbar-area .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.50)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.remove-col-padding-right {
    padding-right: 0;
}

.remove-col-padding-left {
    padding-left: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-right-0 {
    padding-left: 0;
}

.gray-bg {
    background-color: #f8f8f8;
}

//== padding classes
.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-70 {
    padding-top: 70px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-90 {
    padding-top: 90px;
}

.padding-top-100 {
    padding-top: 100px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-70 {
    padding-bottom: 70px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-85 {
    padding-bottom: 85px;
}

.padding-bottom-90 {
    padding-bottom: 90px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .navbar-area .navbar-collapse .navbar-nav .nav-item {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-area {
        width: -moz-available !important;
        width: 100%;
    }
    .logo-wrapper.navbar-brand {
        margin-right: -8em !important;
    }
}

//== padding both

.padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.padding-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.padding-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.padding-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}


//== margin classes
.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-90 {
    margin-top: 90px;
}

.margin-top-100 {
    margin-top: 100px;
}

//= margin bottom
.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-70 {
    margin-bottom: 70px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-90 {
    margin-bottom: 90px;
}

.margin-top-100 {
    margin-bottom: 100px;
}

.margin-top-120 {
    margin-top: 120px;
}

.padding-left-0 {
    padding-left: 0px !important;
}

.back-to-top {

    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    background-color: $yellow;
    color: $white;
    text-align: center;
    line-height: 58px;
    border-radius: 50%;
    z-index: 99;
    font-size: 25px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: none;

    i {
        @include rotate(-45);
    }
}

.btn-boxed {
    display: inline-block;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 700;
    color: $white;
    width: 160px;
    background-color: $secondary-color;
    padding: 0 20px;
    @include transition($transition);
    text-transform: capitalize;

    &:hover {
        color: $white;
        background-color: $base-color;
    }

    &.btn-rounded {
        border-radius: 30px;
    }

    &.blank {
        background-color: transparent;
        border: 2px solid $secondary-color;
        color: $secondary-color;

        &:hover {
            background-color: $base-color;
            color: $white;
        }
    }

    &.gr-bg {
        background-image: -moz-linear-gradient(90deg, rgb(106, 175, 8) 0%, rgb(98, 190, 83) 92%);
        background-image: -webkit-linear-gradient(90deg, rgb(106, 175, 8) 0%, rgb(98, 190, 83) 92%);
        background-image: -ms-linear-gradient(90deg, rgb(106, 175, 8) 0%, rgb(98, 190, 83) 92%);
    }
}

.boxed-btn {
    display: block;
    text-align: center;
    //height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: $white;
    width: max-content;
    background-color: $secondary-color;
    padding: 0 20px;
    @include transition($transition);
    font-family: $heading-font;
    letter-spacing: 1px;

    &:hover {
        color: $white;
        background-color: $base-color;
    }

    &.btn-rounded {
        border-radius: 30px;
    }

    &.blank {
        background-color: transparent;
        border: 2px solid $secondary-color;
        color: $secondary-color;

        &:hover {
            background-color: $base-color;
            color: $white;
        }
    }

    &.reverse-color {
        color: $white;
        background-color: $base-color;

        &:hover {
            color: $white;
            background-color: #ffcc00;
        }
    }
}

.section-title {
    text-align: center;

    &.with-p {
        margin-bottom: 46px;
    }

    &.epad {
        margin-bottom: 50px;
    }

    &.extra {
        .title {
            margin-bottom: 15px;
        }
    }

    &.white {
        .subtitle {
            color: $base-color;
        }

        .title {
            color: $white;
        }

        p {
            color: rgba($white, .70);
        }
    }

    .subtitle {
        font-size: 18px;
        font-weight: 600;
        color: $base-color;
        line-height: 28px;
        display: block;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 15px;
        color: $heading-color;
        font-weight: 700;
        position: relative;
    }

    p {
        max-width: 650px;
        font-size: 16px;
        line-height: 26px;
        color: rgba($heading-color, .80);
        margin: 0 auto;
    }

    margin-bottom: 40px;
}

.c-white {
    color: $white;
}


.base-color {
    color: $base-color;
}


.submit-btn {
    width: 250px;
    height: auto;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: $white;
    background-color: $base-color;
    border: none;
    @include transition($transition);
    cursor: pointer;

    &:hover {
        background-color: #ffcc00;
    }

    &.btn-rounded {
        border-radius: 30px;
    }

    &.btn-center {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
    }

    &:focus {
        outline: none;
    }
}

.section-title-inner {
    text-align: center;

    .subtitle {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        color: $base-color;
        margin-bottom: 20px;
        display: block;
    }

    .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
    }

    margin-bottom: 45px
}

.form-element {
    &.margin-bottom-30 {
        margin-bottom: 27px;
    }

    label {
        color: $heading-color;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;

        span {
            color: #BE1F27;
        }
    }

    select {
        @include selectArrow(none);
    }
}

.has-icon {
    position: relative;
    display: block;

    &.textarea {
        .the-icon {
            top: 25px;
        }
    }

    .input-field {
        @include selectArrow(none);
    }

    .the-icon {
        font-size: 14px;
        position: absolute;
        right: 30px;
        top: 50%;
        @include translateY(-50%);
        color: #7c7c90;
    }
}

.input-field {
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-radius: 5px;
    color: #7c7c90;
    @include placeholder-color(#7c7c90);

    &.borderd {
        border: 2px solid rgba(0, 0, 0, 0.10);

        &:focus {
            border: 2px solid rgba(0, 0, 0, 0.10);
        }
    }

    &.textarea {
        min-height: 120px;
        padding: 20px 30px;
        resize: none;
    }

    &.error {
        border: 2px solid $base-color;
        @include placeholder-color($base-color);

        &:focus {
            border-color: $base-color;
        }
    }
}

.video-play-btn {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 16px;
    background-color: $white;
    border-radius: 50%;
    color: #313131;
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        @include transform(translateX(-50%) translateY(-50%));
        display: block;
        width: 80px;
        height: 80px;
        background: rgba($white, .70);
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
        z-index: -1;
    }

    &:hover {
        color: #313131;
    }
}

.search-popup.active .search-popup-form {
    visibility: visible;
    opacity: 1;
}

.search-popup .search-popup-form {
    min-width: 600px;
    position: absolute;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    visibility: hidden;
    opacity: 0;
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Chrome, Safari, Opera */
    transform: translate(-50%, -50%);
    z-index: 9;
}

.search-popup .search-popup-form .form-element .input-field {
    border: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.search-popup .search-popup-form .submit-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.body-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.54);
    z-index: 9;
    content: '';
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.body-overlay.active {
    visibility: visible;
    opacity: .80;
}

.table-responsive {
    display: table;
}

@include keyframes(pulse-border) {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
        opacity: 0;
    }
}

@media only screen and (max-width: 991px) {
    .row.reorder-xs {
        @include transform(rotate(180deg));
        direction: rtl;
    }
    .row.reorder-xs > [class*="col-"] {
        @include transform(rotate(-180deg));
        direction: ltr;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 30px;

    input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f0faff;
        @include transition(.4s);

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        &:before {
            position: absolute;
            content: "";
            height: 30px;
            width: 30px;
            left: 0px;
            bottom: 0px;
            background-color: $secondary-color;
            @include transition(.4s);
        }
    }
}

input:checked + .slider:before {
    background-color: $base-color;
}

input:checked + .slider {
    background-color: #f0faff;
}

input:focus + .slider {
    -webkit-box-shadow: 0 0 1px #f0faff;
    box-shadow: 0 0 1px #f0faff;
}

input:checked + .slider:before {
    @include translateX(41px);
}

/*---------------------
    Breadcumb Area
----------------------*/
.breadcrumb-area {
    padding: 132px 0 133px 0;
    background-image: url(../../img/bg/breacrumb-bg.jpg);
    position: relative;
    z-index: 0;
    background-size: cover;
    background-position: center;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/breadcrumb-shape.png);
        background-repeat: no-repeat;
        background-position: top left;
        content: '';
        z-index: -1;
    }

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb-actu {
    padding: 132px 0 133px 0;
    background-image: url(../../img/photos-site/4-bandeau-actualité.jpg);
    position: relative;
    z-index: 0;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100%;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top left;
        content: '';
        z-index: -1;
    }

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb-event {
    padding: 132px 0 133px 0;
    background-image: url(../../img/photos-site/5-bandeau-evenement.jpg);
    position: relative;
    z-index: 0;
    background-position: right;
    background-size: 20%;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/breadcrumb-shape.png);
        background-repeat: no-repeat;
        background-position: top left;
        content: '';
        z-index: -1;
    }

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb-about {
    padding: 132px 0 133px 0;
    background-image: url(../../img/photos-site/7-bandeau.jpg);
    position: relative;
    z-index: 0;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100%;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top left;
        content: '';
        z-index: -1;
    }

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb-member {
    padding: 132px 0 133px 0;
    background-image: url(../../img/photos-site/11-bandeau-nos-membres.jpg);
    position: relative;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb-login {
    padding: 132px 0 133px 0;
    background-image: url(../../img/photos-site/13-bandeau-connectez-vous.jpg);
    position: relative;
    z-index: 0;
    background-position: right;
    background-size: 30%;
    height: auto;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../img/bg/breadcrumb-shape.png);
        background-repeat: no-repeat;
        background-position: top left;
        content: '';
        z-index: -1;
    }

    &.extra {
        padding-bottom: 138px;
    }

    .title {
        font-size: 36px;
        line-height: 46px;
        font-weight: 600;
        color: $white;
        margin-bottom: 12px;
    }

    .page-list {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: rgba($white, .70);

            &:first-child {
                padding-left: 0;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                left: 10px;
                top: 0;
                @include fontawesomeIcon("\f105");
            }

            a {
                color: rgba($white, .70);
                @include transition($transition);

                &:hover {
                    color: $white;
                }
            }
        }
    }
}

/*-------------------------
    Preloader Css
---------------------------*/
.pre-wrap {
    position: fixed;
    content: '';
    transform: translate(-100%, -240%);
    font-size: 62px;
}

.preloader-inner {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: $white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel-preloader {
        position: absolute;
        bottom: 30px;
        right: 30px;

        a {
            background-color: $white;
            font-weight: 600;
            color: $base-color;
            width: 200px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 30px;
            display: block;
            @include transition($transition);

            &:hover {
                background-color: $secondary-color;
                color: $white;
            }
        }
    }
}


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid $base-color;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

.section-title h3 {
    color: #fff !important;
}

.pagination {
    margin: 2em auto 0;
    width: max-content;

    .page-item.active .page-link {
        background-color: $violet;
        border-color: $violet;
    }

    .page-link {
        color: $red;
    }
}

@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);

.type-pause-button {
    position: absolute;
    top: 150px;
}

.icon-pause-circle-fill:before {
    content: "\e600";
    font-size: 4em;
    color: #666;
}

.icon-play-circle-fill:before {
    content: "\e601";
    font-size: 4em;
    color: #666;
}

.dynamic-title {
    float: left;
    display: table;
    width: auto;
    font-size: 54px;
    line-height: 38px;
    font-weight: 700;
}

div#text-btn button {
    position: absolute;
    display: inline-block;
    top: 200px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    overflow: hidden;
    transition-delay: 0.2s;
    transition: .3s background;
    outline: none;
    font-size: 15px;
    font-family: $heading-font;
    font-weight: 300;
}

div#text-btn button:hover {
    background: rgba(0, 0, 0, 0.5);
}

.pause-btn.transparent {
    background: transparent;
}

span.gold-title {
    font-size: 38px !important;
    margin: -10px 0;
}

/* --------------------------------

Primary style

-------------------------------- */
.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
    font-style: normal;
}

.cd-words-wrapper i {
    font-style: normal;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* --------------------------------

xrotate-1

-------------------------------- */
.cd-headline.rotate-1 .cd-words-wrapper {
    -webkit-perspective: 300px;
    -moz-perspective: 300px;
    perspective: 300px;
}

.cd-headline.rotate-1 b {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.cd-headline.rotate-1 b.is-visible {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-animation: cd-rotate-1-in 1.2s;
    -moz-animation: cd-rotate-1-in 1.2s;
    animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-animation: cd-rotate-1-out 1.2s;
    -moz-animation: cd-rotate-1-out 1.2s;
    animation: cd-rotate-1-out 1.2s;
}

@-webkit-keyframes cd-rotate-1-in {
    0% {
        -webkit-transform: rotateX(180deg);
        opacity: 0;
    }
    35% {
        -webkit-transform: rotateX(120deg);
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(360deg);
        opacity: 1;
    }
}

@-moz-keyframes cd-rotate-1-in {
    0% {
        -moz-transform: rotateX(180deg);
        opacity: 0;
    }
    35% {
        -moz-transform: rotateX(120deg);
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    100% {
        -moz-transform: rotateX(360deg);
        opacity: 1;
    }
}

@keyframes cd-rotate-1-in {
    0% {
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0;
    }
    35% {
        -webkit-transform: rotateX(120deg);
        -moz-transform: rotateX(120deg);
        -ms-transform: rotateX(120deg);
        -o-transform: rotateX(120deg);
        transform: rotateX(120deg);
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(360deg);
        -moz-transform: rotateX(360deg);
        -ms-transform: rotateX(360deg);
        -o-transform: rotateX(360deg);
        transform: rotateX(360deg);
        opacity: 1;
    }
}

@-webkit-keyframes cd-rotate-1-out {
    0% {
        -webkit-transform: rotateX(0deg);
        opacity: 1;
    }
    35% {
        -webkit-transform: rotateX(-40deg);
        opacity: 1;
    }
    65% {
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(180deg);
        opacity: 0;
    }
}

@-moz-keyframes cd-rotate-1-out {
    0% {
        -moz-transform: rotateX(0deg);
        opacity: 1;
    }
    35% {
        -moz-transform: rotateX(-40deg);
        opacity: 1;
    }
    65% {
        opacity: 0;
    }
    100% {
        -moz-transform: rotateX(180deg);
        opacity: 0;
    }
}

@keyframes cd-rotate-1-out {
    0% {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        opacity: 1;
    }
    35% {
        -webkit-transform: rotateX(-40deg);
        -moz-transform: rotateX(-40deg);
        -ms-transform: rotateX(-40deg);
        -o-transform: rotateX(-40deg);
        transform: rotateX(-40deg);
        opacity: 1;
    }
    65% {
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
        transform: rotateX(180deg);
        opacity: 0;
    }
}

/* --------------------------------

xtype

-------------------------------- */
.cd-headline.type .cd-words-wrapper {
    vertical-align: top;
    overflow: hidden;
}

.cd-headline.type .cd-words-wrapper::after {
    /* vertical bar */
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 90%;
    width: 1px;
    background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.waiting::after {
    -webkit-animation: cd-pulse 1s infinite;
    -moz-animation: cd-pulse 1s infinite;
    animation: cd-pulse 1s infinite;
}

.cd-headline.type .cd-words-wrapper.selected {
    background-color: #aebcb9;
}

.cd-headline.type .cd-words-wrapper.selected::after {
    visibility: hidden;
}

.cd-headline.type .cd-words-wrapper.selected b {
    color: #0d0d0d;
}

.cd-headline.type b {
    visibility: hidden;
}

.cd-headline.type b.is-visible {
    visibility: visible;
}

.cd-headline.type i {
    position: absolute;
    visibility: hidden;
}

.cd-headline.type i.in {
    position: relative;
    visibility: visible;
}

@-webkit-keyframes cd-pulse {
    0% {
        -webkit-transform: translateY(-50%) scale(1);
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(-50%) scale(0.9);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-50%) scale(0);
        opacity: 0;
    }
}

@-moz-keyframes cd-pulse {
    0% {
        -moz-transform: translateY(-50%) scale(1);
        opacity: 1;
    }
    40% {
        -moz-transform: translateY(-50%) scale(0.9);
        opacity: 0;
    }
    100% {
        -moz-transform: translateY(-50%) scale(0);
        opacity: 0;
    }
}

@keyframes cd-pulse {
    0% {
        -webkit-transform: translateY(-50%) scale(1);
        -moz-transform: translateY(-50%) scale(1);
        -ms-transform: translateY(-50%) scale(1);
        -o-transform: translateY(-50%) scale(1);
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(-50%) scale(0.9);
        -moz-transform: translateY(-50%) scale(0.9);
        -ms-transform: translateY(-50%) scale(0.9);
        -o-transform: translateY(-50%) scale(0.9);
        transform: translateY(-50%) scale(0.9);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(-50%) scale(0);
        -moz-transform: translateY(-50%) scale(0);
        -ms-transform: translateY(-50%) scale(0);
        -o-transform: translateY(-50%) scale(0);
        transform: translateY(-50%) scale(0);
        opacity: 0;
    }
}

/* --------------------------------

xrotate-2

-------------------------------- */
.cd-headline.rotate-2 .cd-words-wrapper {
    -webkit-perspective: 300px;
    -moz-perspective: 300px;
    perspective: 300px;
}

.cd-headline.rotate-2 i, .cd-headline.rotate-2 em {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cd-headline.rotate-2 i {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(-20px) rotateX(90deg);
    -moz-transform: translateZ(-20px) rotateX(90deg);
    -ms-transform: translateZ(-20px) rotateX(90deg);
    -o-transform: translateZ(-20px) rotateX(90deg);
    transform: translateZ(-20px) rotateX(90deg);
    opacity: 0;
}

.is-visible .cd-headline.rotate-2 i {
    opacity: 1;
}

.cd-headline.rotate-2 i.in {
    -webkit-animation: cd-rotate-2-in 0.4s forwards;
    -moz-animation: cd-rotate-2-in 0.4s forwards;
    animation: cd-rotate-2-in 0.4s forwards;
}

.cd-headline.rotate-2 i.out {
    -webkit-animation: cd-rotate-2-out 0.4s forwards;
    -moz-animation: cd-rotate-2-out 0.4s forwards;
    animation: cd-rotate-2-out 0.4s forwards;
}

.cd-headline.rotate-2 em {
    -webkit-transform: translateZ(20px);
    -moz-transform: translateZ(20px);
    -ms-transform: translateZ(20px);
    -o-transform: translateZ(20px);
    transform: translateZ(20px);
}

.no-csstransitions .cd-headline.rotate-2 i {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 0;
}

.no-csstransitions .cd-headline.rotate-2 i em {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.no-csstransitions .cd-headline.rotate-2 .is-visible i {
    opacity: 1;
}

@-webkit-keyframes cd-rotate-2-in {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(90deg);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(-10deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(0deg);
    }
}

@-moz-keyframes cd-rotate-2-in {
    0% {
        opacity: 0;
        -moz-transform: translateZ(-20px) rotateX(90deg);
    }
    60% {
        opacity: 1;
        -moz-transform: translateZ(-20px) rotateX(-10deg);
    }
    100% {
        opacity: 1;
        -moz-transform: translateZ(-20px) rotateX(0deg);
    }
}

@keyframes cd-rotate-2-in {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(90deg);
        -moz-transform: translateZ(-20px) rotateX(90deg);
        -ms-transform: translateZ(-20px) rotateX(90deg);
        -o-transform: translateZ(-20px) rotateX(90deg);
        transform: translateZ(-20px) rotateX(90deg);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(-10deg);
        -moz-transform: translateZ(-20px) rotateX(-10deg);
        -ms-transform: translateZ(-20px) rotateX(-10deg);
        -o-transform: translateZ(-20px) rotateX(-10deg);
        transform: translateZ(-20px) rotateX(-10deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(0deg);
        -moz-transform: translateZ(-20px) rotateX(0deg);
        -ms-transform: translateZ(-20px) rotateX(0deg);
        -o-transform: translateZ(-20px) rotateX(0deg);
        transform: translateZ(-20px) rotateX(0deg);
    }
}

@-webkit-keyframes cd-rotate-2-out {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(-100deg);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(-90deg);
    }
}

@-moz-keyframes cd-rotate-2-out {
    0% {
        opacity: 1;
        -moz-transform: translateZ(-20px) rotateX(0);
    }
    60% {
        opacity: 0;
        -moz-transform: translateZ(-20px) rotateX(-100deg);
    }
    100% {
        opacity: 0;
        -moz-transform: translateZ(-20px) rotateX(-90deg);
    }
}

@keyframes cd-rotate-2-out {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(-20px) rotateX(0);
        -moz-transform: translateZ(-20px) rotateX(0);
        -ms-transform: translateZ(-20px) rotateX(0);
        -o-transform: translateZ(-20px) rotateX(0);
        transform: translateZ(-20px) rotateX(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(-100deg);
        -moz-transform: translateZ(-20px) rotateX(-100deg);
        -ms-transform: translateZ(-20px) rotateX(-100deg);
        -o-transform: translateZ(-20px) rotateX(-100deg);
        transform: translateZ(-20px) rotateX(-100deg);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateZ(-20px) rotateX(-90deg);
        -moz-transform: translateZ(-20px) rotateX(-90deg);
        -ms-transform: translateZ(-20px) rotateX(-90deg);
        -o-transform: translateZ(-20px) rotateX(-90deg);
        transform: translateZ(-20px) rotateX(-90deg);
    }
}

/* --------------------------------

xloading-bar

-------------------------------- */
.cd-headline.loading-bar span {
    display: inline-block;
    padding: .2em 0;
}

.cd-headline.loading-bar .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
    /* loading bar */
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: #0096a7;
    z-index: 2;
    -webkit-transition: width 0.3s -0.1s;
    -moz-transition: width 0.3s -0.1s;
    transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
    width: 100%;
    -webkit-transition: width 3s;
    -moz-transition: width 3s;
    transition: width 3s;
}

.cd-headline.loading-bar b {
    top: .2em;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.cd-headline.loading-bar b.is-visible {
    opacity: 1;
    top: 0;
}

/* --------------------------------

xslide

-------------------------------- */
.cd-headline.slide span {
    display: inline-block;
    padding: .2em 0;
}

.cd-headline.slide .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.slide b {
    opacity: 0;
    top: .2em;
}

.cd-headline.slide b.is-visible {
    top: 0;
    opacity: 1;
    -webkit-animation: slide-in 0.6s;
    -moz-animation: slide-in 0.6s;
    animation: slide-in 0.6s;
}

.cd-headline.slide b.is-hidden {
    -webkit-animation: slide-out 0.6s;
    -moz-animation: slide-out 0.6s;
    animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(20%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes slide-in {
    0% {
        opacity: 0;
        -moz-transform: translateY(-100%);
    }
    60% {
        opacity: 1;
        -moz-transform: translateY(20%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(20%);
        -moz-transform: translateY(20%);
        -ms-transform: translateY(20%);
        -o-transform: translateY(20%);
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-out {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateY(120%);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
}

@-moz-keyframes slide-out {
    0% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
    60% {
        opacity: 0;
        -moz-transform: translateY(120%);
    }
    100% {
        opacity: 0;
        -moz-transform: translateY(100%);
    }
}

@keyframes slide-out {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateY(120%);
        -moz-transform: translateY(120%);
        -ms-transform: translateY(120%);
        -o-transform: translateY(120%);
        transform: translateY(120%);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
        transform: translateY(100%);
    }
}

/* --------------------------------

xclip

-------------------------------- */
.cd-headline.clip span {
    display: inline-block;
    padding: .2em 0;
}

.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
    /* line */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #aebcb9;
}

.cd-headline.clip b {
    opacity: 0;
}

.cd-headline.clip b.is-visible {
    opacity: 1;
}

/* --------------------------------

xzoom

-------------------------------- */
.cd-headline.zoom .cd-words-wrapper {
    -webkit-perspective: 300px;
    -moz-perspective: 300px;
    perspective: 300px;
}

.cd-headline.zoom b {
    opacity: 0;
}

.cd-headline.zoom b.is-visible {
    opacity: 1;
    -webkit-animation: zoom-in 0.8s;
    -moz-animation: zoom-in 0.8s;
    animation: zoom-in 0.8s;
}

.cd-headline.zoom b.is-hidden {
    -webkit-animation: zoom-out 0.8s;
    -moz-animation: zoom-out 0.8s;
    animation: zoom-out 0.8s;
}

@-webkit-keyframes zoom-in {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
    }
}

@-moz-keyframes zoom-in {
    0% {
        opacity: 0;
        -moz-transform: translateZ(100px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateZ(0);
    }
}

@keyframes zoom-in {
    0% {
        opacity: 0;
        -webkit-transform: translateZ(100px);
        -moz-transform: translateZ(100px);
        -ms-transform: translateZ(100px);
        -o-transform: translateZ(100px);
        transform: translateZ(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@-webkit-keyframes zoom-out {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateZ(-100px);
    }
}

@-moz-keyframes zoom-out {
    0% {
        opacity: 1;
        -moz-transform: translateZ(0);
    }
    100% {
        opacity: 0;
        -moz-transform: translateZ(-100px);
    }
}

@keyframes zoom-out {
    0% {
        opacity: 1;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateZ(-100px);
        -moz-transform: translateZ(-100px);
        -ms-transform: translateZ(-100px);
        -o-transform: translateZ(-100px);
        transform: translateZ(-100px);
    }
}

/* --------------------------------

xrotate-3

-------------------------------- */
.cd-headline.rotate-3 .cd-words-wrapper {
    -webkit-perspective: 300px;
    -moz-perspective: 300px;
    perspective: 300px;
}

.cd-headline.rotate-3 i {
    display: inline-block;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.is-visible .cd-headline.rotate-3 i {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.cd-headline.rotate-3 i.in {
    -webkit-animation: cd-rotate-3-in 0.6s forwards;
    -moz-animation: cd-rotate-3-in 0.6s forwards;
    animation: cd-rotate-3-in 0.6s forwards;
}

.cd-headline.rotate-3 i.out {
    -webkit-animation: cd-rotate-3-out 0.6s forwards;
    -moz-animation: cd-rotate-3-out 0.6s forwards;
    animation: cd-rotate-3-out 0.6s forwards;
}

.no-csstransitions .cd-headline.rotate-3 i {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
    opacity: 0;
}

.no-csstransitions .cd-headline.rotate-3 .is-visible i {
    opacity: 1;
}

@-webkit-keyframes cd-rotate-3-in {
    0% {
        -webkit-transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
    }
}

@-moz-keyframes cd-rotate-3-in {
    0% {
        -moz-transform: rotateY(180deg);
    }
    100% {
        -moz-transform: rotateY(0deg);
    }
}

@keyframes cd-rotate-3-in {
    0% {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}

@-webkit-keyframes cd-rotate-3-out {
    0% {
        -webkit-transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
    }
}

@-moz-keyframes cd-rotate-3-out {
    0% {
        -moz-transform: rotateY(0);
    }
    100% {
        -moz-transform: rotateY(-180deg);
    }
}

@keyframes cd-rotate-3-out {
    0% {
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        -ms-transform: rotateY(0);
        -o-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        -o-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

/* --------------------------------

xscale

-------------------------------- */
.cd-headline.scale i {
    display: inline-block;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
}

.is-visible .cd-headline.scale i {
    opacity: 1;
}

.cd-headline.scale i.in {
    -webkit-animation: scale-up 0.6s forwards;
    -moz-animation: scale-up 0.6s forwards;
    animation: scale-up 0.6s forwards;
}

.cd-headline.scale i.out {
    -webkit-animation: scale-down 0.6s forwards;
    -moz-animation: scale-down 0.6s forwards;
    animation: scale-down 0.6s forwards;
}

.no-csstransitions .cd-headline.scale i {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
}

.no-csstransitions .cd-headline.scale .is-visible i {
    opacity: 1;
}

@-webkit-keyframes scale-up {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.2);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@-moz-keyframes scale-up {
    0% {
        -moz-transform: scale(0);
        opacity: 0;
    }
    60% {
        -moz-transform: scale(1.2);
        opacity: 1;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-up {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    60% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes scale-down {
    0% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    60% {
        -webkit-transform: scale(0);
        opacity: 0;
    }
}

@-moz-keyframes scale-down {
    0% {
        -moz-transform: scale(1);
        opacity: 1;
    }
    60% {
        -moz-transform: scale(0);
        opacity: 0;
    }
}

@keyframes scale-down {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    60% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

/* --------------------------------

xpush

-------------------------------- */
.cd-headline.push b {
    opacity: 0;
}

.cd-headline.push b.is-visible {
    opacity: 1;
    -webkit-animation: push-in 0.6s;
    -moz-animation: push-in 0.6s;
    animation: push-in 0.6s;
}

.cd-headline.push b.is-hidden {
    -webkit-animation: push-out 0.6s;
    -moz-animation: push-out 0.6s;
    animation: push-out 0.6s;
}

@-webkit-keyframes push-in {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes push-in {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    60% {
        opacity: 1;
        -moz-transform: translateX(10%);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes push-in {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateX(10%);
        -moz-transform: translateX(10%);
        -ms-transform: translateX(10%);
        -o-transform: translateX(10%);
        transform: translateX(10%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes push-out {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateX(110%);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }
}

@-moz-keyframes push-out {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
    60% {
        opacity: 0;
        -moz-transform: translateX(110%);
    }
    100% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }
}

@keyframes push-out {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    60% {
        opacity: 0;
        -webkit-transform: translateX(110%);
        -moz-transform: translateX(110%);
        -ms-transform: translateX(110%);
        -o-transform: translateX(110%);
        transform: translateX(110%);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
}
