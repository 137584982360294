/*-----------------------------
    Contact Page
-----------------------------*/

.contact-page-area {
    .section-title {
        margin-bottom: 45px;
    }
}

footer .contact-form {
    .form-group {
        .form-control {
            &::placeholder {
                color: white;
            }
            width: 100%;
            height: 50px;
            background-color: transparent;
            color: white;
            border: 0;
            border-bottom: 2px solid white;
            border-radius: 0;
        }
        &.textarea {
            .form-control {
                min-height: 250px;
                resize: none;
            }
        }
    }
    .submit-btn {
        margin: 0 auto;
        display: block;
        font-weight: 600;
        font-size: 16px;
    }
    .column {
        width: 90%;
    }
}

.single-contact-info {
    padding: 15px 10px 15px 10px;
    margin: 5px;
    &.white {
        .icon {
            color: $white;
        }
        .content {
            .title {
                color: $white;
            }
        }
    }
    display: flex;
    align-items: flex-start;
    .icon {
        font-size: 40px;
        line-height: 50px;
        margin-right: 30px;
    }
    .content {
        .title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
        }
        .details {
            display: block;
        }
    }
}

.map-area {
    #map {
        min-height: 600px;
    }
}

.forgot {
    a {
        padding-left: 50px;
        font-size: 14px;
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: $base-color;
        outline: none;
    }
    a i {
        padding: 0 2px;
    }
}

.recaptcha {
    top: -2425px;
    left: -210px;
    width: max-content;
    position: absolute;
}