
/*
--------------------------------
	Css Indexing
	----------------------------
	Table of Css Content
--------------------------------
** @typography
--------------------------------
** Global style
--------------------------------
** Breadcumb Area
-------------------------------- 
** Preloader
--------------------------------
** Normalize
--------------------------------
** Navbar Area
--------------------------------
** Header Area
--------------------------------
** Support Area
--------------------------------
** Sidebar Area
--------------------------------
** About Area
--------------------------------
** Call To Action Area
--------------------------------
** What we do Area
--------------------------------
** Service Area
--------------------------------
** Price Plan Area
--------------------------------
** Team Member Area
--------------------------------
** Blog Area
--------------------------------
** Testimonial Area
--------------------------------
** Footer Area
--------------------------------




--------------------------------
** About Page
--------------------------------
** Portfolio Page
--------------------------------
** Portfolio Details Page
--------------------------------
** Service Details Page
--------------------------------
** Blog Page
--------------------------------
** Blog Details Page
--------------------------------
** Contact Page
--------------------------------
** Team Member Page
--------------------------------
** Faq Page
--------------------------------


*/

@import "../css/bootstrap.min.css";

@import "main/_variables";
@import "main/_mixins";
@import "main/_global";
@import "main/_normalize";

//=Section scss
@import "sections/_navbar";
@import "sections/_header";
@import "sections/_support";
@import "sections/_sidebar";
@import "sections/_about";
@import "sections/_call_to_action";
@import "sections/_what_we_do";
@import "sections/_service";
@import "sections/_price_plan";
@import "sections/_team_member";
@import "sections/_blog";
@import "sections/_testimonial";
@import "sections/_footer_area";

//=Pages scss
@import "pages/_about";
@import "pages/_portfolio";
@import "pages/_portfolio_details";
@import "pages/_service_details";
@import "pages/_blog";
@import "pages/_blog_details";
@import "pages/_contact";
@import "pages/_team";
@import "pages/_faq";
@import "pages/_error_page";


@media only screen and (max-width: 692px) {
	.logo-wrapper img {
		max-width: 62% !important;
	}
	
	span.gold-title {
		font-size: 23px !important;
	}
}