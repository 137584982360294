/*---------------------------
    Call TO Action
----------------------------*/
.call-to-action-area{
    background-color: #1531a9;
    padding: 90px 0 90px;
}

.single-counter-item{
    text-align: center;
    .count-num{
        font-size: 45px;
        line-height: 55px;
        color: $base-color;
        font-weight: 600;
    }
    .title{
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: rgba($white,.8);
    }
}
.call-to-action-area-two{
    position: relative;
    z-index: 0;
    padding: 70px 0 78px 0;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#0141f5,.55);
        content: '';
        z-index: -1;
    }
    &.counterup-bg{
        background-position: center;
        background-size: cover;
        background-image: url(../../img/bg/counterup-bg.jpg);
    }
    .left-content{
        display: inline-block;
        .title{
            font-size: 36px;
            line-height: 46px;
            color: $white;
            font-weight: 600;
        }
        p{
            font-size: 16px;
            line-height: 26px;
            color: rgba($white,.8);
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .right-content{
        display: inline-block;
        float: right;
        .btn-wrapper {
            margin-top: 17px;
        }
    }
}