/*------------------------
    Service Area
-------------------------*/
.service-area-three {
  padding: 100px 0 70px 0;
}

.service-area {
  padding: 140px 0 150px 0;
  position: relative;
  z-index: 0;

  &.style-two {
	padding: 95px 0 70px 0;
  }

  .shaped-bg-1 {
	position: absolute;
	left: 0;
	top: 0;
  }

  .section-title {
	.title {
	  color: #FF0000;
	  font-size: 45px;
	}
  }
}


.single-service-item {
  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;

  &:hover {
	.content {
	  .title {
		color: $base-color;
	  }
	}
  }

  .icon {
	font-size: 50px;
	line-height: 60px;
	color: $base-color;
	margin-right: 30px;
  }

  .content {
	.title {
	  font-size: 18px;
	  line-height: 28px;
	  font-weight: 600;
	  @include transition($transition);
	}

	p {
	  font-size: 16px;
	  line-height: 26px;
	}
  }
}


.single-service-item-two {
  text-align: center;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.09);
  padding: 52px 30px 50px 30px;
  margin-bottom: 30px;
  background-color: $white;

  &:hover {
	background-image: -moz-linear-gradient(0deg, rgb(21, 49, 169) 0%, rgb(103, 55, 241) 100%);
	background-image: -webkit-linear-gradient(0deg, rgb(21, 49, 169) 0%, rgb(103, 55, 241) 100%);
	background-image: -ms-linear-gradient(0deg, rgb(21, 49, 169) 0%, rgb(103, 55, 241) 100%);

	.icon {
	  color: $white;
	}

	.content {
	  .title {
		a {
		  color: $white;
		}
	  }

	  p {
		color: rgba($white, .8);
	  }
	}
  }

  .icon {
	font-size: 50px;
	line-height: 60px;
	color: $base-color;
	margin-bottom: 18px;
  }

  .content {
	.title {
	  font-size: 24px;
	  line-height: 34px;
	  font-weight: 600;
	  margin-bottom: 22px;

	  a {
		color: $heading-color;
		@include transition($transition);

		&:hover {
		  color: $base-color;
		}
	  }
	}

	p {
	  font-size: 16px;
	  line-height: 26px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }
}

.single-service-item-three {
  margin-bottom: 30px;

  .thumb {
	position: relative;
	z-index: 0;

	.cat {
	  position: absolute;
	  left: 0;
	  bottom: 0;
	  background-color: $base-color;
	  padding: 5px 15px;
	  color: rgba($white, .9);

	  a {
		color: rgba($white, .9);
		position: relative;
		z-index: 0;
		margin: 0 5px;
		@include transition($transition);

		&:hover {
		  color: $white;
		}

		&:first-child {
		  margin-left: 0;
		}

		&:last-child {
		  margin-right: 0;

		  &:after {
			display: none;
		  }
		}

		&:after {
		  position: absolute;
		  right: -6px;
		  top: 0;
		  content: ',';
		}
	  }
	}
  }

  .content {
	background-color: $white;
	padding: 24px 30px 22px 30px;

	.title {
	  a {
		font-size: 18px;
		line-height: 26px;
		font-weight: 600;
		@include transition($transition);

		&:hover {
		  color: $base-color;
		}
	  }

	  margin-bottom: 15px;
	}

	p {
	  font-size: 16px;
	  line-height: 26px;
	  color: $descr-color;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }
}
