/*-----------------------
    Service Details
-----------------------*/
.service-details-content{
    padding: 100px 0 80px 0;
}
.service-content-area{
    .entry-content{
        .title{
            margin-top: 25px;
            margin-bottom: 15px;
            font-size: 24px;
            line-height: 34px;
            color: $heading-color;
            font-weight: 600;
        }
        .post-meta{
            margin: 0;
            padding: 0;
            list-style: none;
            margin-bottom: 22px;
            li{
                margin: 0 10px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.date{
                    a{
                        &:after{
                            display: none;
                        }
                    }
                }
                display: inline-block;
                a{
                    color: $descr-color;
                    position: relative;
                    z-index: 0;
                    margin: 0 5px;
                    @include transition($transition);
                    &:hover{
                        color: $base-color;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                        &:after{
                            display: none;
                        }
                    }
                    &:after{
                        position: absolute;
                        right: -5px;
                        top: 0;
                        content: ',';
                    }
                }
            }
        }
    }
}
.service-sidebar{

}