/*-----------------------
    About us page
------------------------*/
.our-history-area{
    padding: 0 0 20px 0;
    .section-title{
        margin-bottom: 45px;
    }
}

.single-history-item{
    &:last-child{
        margin-bottom: 0px;
    }
    margin-bottom: 50px;
    .content-wrapper{
        .title{
            line-height: 34px;
            font-weight: 600;
        }
        p{
            font-size: 16px;
            line-height: 26px;
            color: $descr-color;
        }
    }
}

.single-team-member{
    margin-bottom: 20px;
}